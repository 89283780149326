.button{
    color: #fff;
    background-color: #0d6efd;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid #0d6efd;
    padding: 0.375rem 2rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin: 0 0 0 10px;
}

.button:hover{
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
}

.button:active {
    color: #fff;
    background-color: #0a58ca;
    border-color: #0a53be;
}

.button:focus{
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
    box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
}

.button:disabled{
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
    pointer-events: none;
    opacity: .65;
}