.parcel{
    background-color: #cfe2ff;
    padding: 10px;
    border-radius: 20px;
    color: #084298;
    width: 100%;
}

.parcel--delivery{
    background-color: #d1e7dd;
    color: #0f5132;
}

.parcel--cancel{
    background-color: #f8d7da;
    color: #842029;
}

.parcel__head{
    display: flex;
    background-color: rgba(255,255,255,0.4);
    border-radius: 10px;
    padding: 10px;
}

.parcel__created-at{
    padding: 0 10px 0 0;
}

.parcel__track-number{
    padding: 0 20px 0 0;
    font-weight: 500;
}

