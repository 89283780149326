.layout{
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
}
.selector{
    border-radius: 5px;
    width: 370px;
    padding: 25px;
    background-color: white;
}
.pd-10 {
   
    padding: 10px;
}