.parcel-event{
    display: flex;
    padding: 5px 0;
}

.parcel-event__date{
    padding: 0 10px 0 0;
    font-style: italic;
}

.parcel-event__type{
    font-weight: 500;
}

