@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  /*border: 5px solid black;*/
  display: flex;
  width: 100%;
  min-height: 100%;
}

body {
  /*border: 5px solid green;*/
  margin: 0;
  width: 100%;
  min-height: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  background-color: white;
  color: #222222;
}

#root {
  /*border: 5px solid blue;*/
  min-height: 100%;
  display: flex;
}

a{
  color: #0088A3;
}